import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

function ensureLeadingSlash(permalink) {
  if (!permalink) {
    return "/"; // this is likely a missing link!
  }
  if ((/^http/).test(permalink)) {
    return permalink;
  }
  // First, ensure that we are starting with a slash
  if (permalink.slice(0, 1) !== "/" && permalink.slice(0, 4) !== "http") {
    return `/${permalink}`;
  }
  return permalink;
}

function Photo({ photo, title, permalink }) {
  const file = photo[0].file;
  const imageUrl = file.url;

  return (
    <div className="p-4 w-full md:w-1/3 sm:mb-0 mb-6 cursor-pointer hover:bg-gray-200" onClick={() => navigate(ensureLeadingSlash(permalink))}>
      <div className="rounded-lg h-64 overflow-hidden">
        <img alt="content" className="object-cover object-center h-full w-full" src={`${imageUrl}?w=400`} />
      </div>
      <h2 className="text-xl font-medium title-font text-gray-900 mt-5">{title}</h2>
    </div>
  );
}


class FieldTripsTemplate extends React.Component {
  render() {
    const { permalink, fieldTrips } = this.props.pageContext;
    const sectionStyle = {};

    return (
      <Layout>
        <SEO title="Field Trips &amp; Events" type="article" permalink={`${process.env.GATSBY_APP_URL}${permalink}`} />
        <section className="text-gray-700 body-font" style={sectionStyle}>
          <div className="container px-5 py-12 mx-auto">
            <div className="flex flex-col text-left w-full lg:w-2/3 mb-20">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Field Trips &amp; Events</h1>
            </div>

            <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
              {fieldTrips.map(item => <Photo key={item.node.id} {...item.node} />)}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
export default FieldTripsTemplate;
